<template>
  <section
    v-if="counts"
    class="m-connected blue--bg"
    data-test="connected-tariff-block"
  >
    <h2 class="m-connected__title headline-2">
      За 7 лет сберегли время и деньги
      <span class="headline">{{ numberFormat(counts.totalClients) }}</span>
      клиентам
    </h2>
    <p class="m-connected__subtitle title-2">
      Недавно подключенные тарифы:
    </p>
    <div class="m-connected__cards d-flex flex-column">
      <div
        v-for="(card, index) in latestConnected"
        :key="card.time + index + card.tariff.id"
        class="m-connected__card white--bg d-flex align-center"
      >
        <div class="m-connected__card-date">
          <p class="headline-6">
            {{ getTime(card.time) }}
          </p>
          <p class="subhead-3 gray-dark--text">
            {{ getDate(card.time) }}
          </p>
          <p class="title-5">
            {{ card.cityName.replace("г ", "г&nbsp;") }}
          </p>
        </div>
        <div class="m-connected__card-tariff d-flex align-center">
          <div class="m-connected__card-logo">
            <img
              loading="lazy"
              :src="/.*\.svg/.test(card?.tariff?.provider?.logoT?.unoptimized)
                ? card?.tariff?.provider?.logoT?.unoptimized
                : card?.tariff?.provider?.logoT?.url"
              :alt="card.tariff.provider.name"
            >
            <p class="subhead-3 gray-dark--text text-no-wrap hidden">
              {{ card.tariff.name }}
            </p>
          </div>
          <div class="m-connected__card-services title-5 d-flex flex-column">
            <div
              v-if="card.tariff.services.internetSpeed"
              class="m-connected__card-service d-flex"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/globe.svg"
                alt="globe"
                class="d-flex"
              >
              <p>
                <span
                  v-if="card.tariff.markers?.wireless"
                  class="gray-dark--text subhead-3"
                >До </span>{{ card.tariff.services.internetSpeed }} Мбит/с
              </p>
            </div>
            <div
              v-if="card.tariff.markers?.wireless && card.tariff.services.wirelessInternetGb"
              class="m-connected__card-service d-flex"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/radio.svg"
                alt="tv"
                class="d-flex"
              >
              <p>{{ card.tariff.services.wirelessInternetGb }} Гб/мес.</p>
            </div>
            <div
              v-if="card.tariff.services.tvChannelsCount"
              class="m-connected__card-service d-flex"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/tv.svg"
                alt="tv"
                class="d-flex"
              >
              <p>
                {{ card.tariff.services.tvChannelsCount }} Канал{{
                  counter(card.tariff.services.tvChannelsCount)
                }}
              </p>
            </div>
            <div
              v-if="
                card.tariff.services.mobileGigabytes
                  || card.tariff.services.mobileMinutes
              "
              class="m-connected__card-service d-flex"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/mobile.svg"
                alt="mobile"
              >
              <p
                v-if="card.tariff.services.mobileGigabytes"
                style="margin-right: 4px"
              >
                {{ card.tariff.services.mobileGigabytes }} Гб
              </p>
              <p v-if="card.tariff.services.mobileMinutes">
                {{ card.tariff.services.mobileMinutes }} Мин
              </p>
            </div>
            <div
              v-if="card.tariff.services.cctvCameraCount"
              class="m-connected__card-service d-flex"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/camera.svg"
                alt="camera"
              >
              <p>
                {{ card.tariff.services.cctvCameraCount }} Камер{{
                  counter(card.tariff.services.cctvCameraCount, "а", "ы", "")
                }}
              </p>
            </div>
          </div>
          <div class="m-connected__card-price">
            <div
              v-if="
                card.tariff.payment.complexPrice
                  && card.tariff.payment.complexPrice.length > 1
              "
              class="m-connected__complex d-flex flex-column"
            >
              <div
                v-for="(price, index) in card.tariff.payment.complexPrice"
                :key="'complex' + index"
                class="m-connected__complex-price d-flex align-center"
                :class="{
                  'white--text m-connected__complex-promo ': price.isPromoPrice,
                  'white--text m-connected__complex-overpromo':
                    price.promoDisplayPercents && !price.isPromoPrice,
                  'm-connected__complex-month': !price.promoDisplayPercents,
                }"
              >
                <template v-if="price.promoDisplayPercents">
                  <p
                    v-if="price.isPromoPrice"
                    class="headline-4 text-no-wrap"
                  >
                    {{ price.price }} ₽/мес
                  </p>
                  <span class="d-block subhead-3">{{
                    price.isPromoPrice
                      ? shortMonth(price.promoDisplayPercents)
                      : price.promoDisplay
                  }}</span>
                </template>
                <p
                  v-else
                  class="title-4"
                >
                  {{ price.price }} ₽
                  <span class="subhead-3">с{{ price.fromMonth === 2 ? "о" : "" }}
                    {{ price.fromMonth }}-го месяца</span>
                </p>
              </div>
            </div>
            <div
              v-else
              class="m-connected__price-monthly headline-4"
            >
              {{ card.tariff.payment.complexPrice[0].price }} ₽/мес
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-connected__footer white--bg d-flex align-center">
      <p class="headline-6">
        Узнайте, какие провайдеры и тарифы доступны в доме
      </p>
      <d-btn
        class="title-4"
        color="primary"
        @click="showDialogAddress"
      >
        Найти тарифы
      </d-btn>
    </div>
  </section>
</template>

<script setup lang="ts">
// import {counter} from "~/helpers";
import { counter, numberFormat } from '~/helpers'

defineProps({
  counts: {},
  latestConnected: {},
})

const ctx = useNuxtApp()
const showDialogAddress = () => {
  ctx.$event('addressDialog', {
    redirect: true,
    label: 'latest-connected',
    title: 'Введите адрес и получите список доступных провайдеров и тарифов',
    goToPage: true,
  })
}

const getTime = (date: Date) => {
  const utcDate = new Date(date)
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
  const formattedTime = utcDate.toLocaleTimeString('ru-RU', options)
  return formattedTime
}

const getDate = (date: Date) => {
  const utcDate = new Date(date)
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  const formattedDate = utcDate.toLocaleDateString('ru-RU', options)
  return formattedDate
}
const shortMonth = (text: string) => {
  return text ? text.replace(/%.*/, '%') : text
}
</script>

<style scoped lang="scss">
.m-connected {
  margin: 64px auto;
  width: 980px;
  max-width: 100vw;
  border-radius: 16px;
  padding: 40px;
  @media (max-width: getBreakpoint(desktop)) {
    width: 100%;
    border-radius: 0;
    padding: 40px 16px;
    margin: 48px 0 0;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    margin-top: 40px;
    padding: 40px 8px;
  }

  &__title {
    margin-bottom: 16px;
  }
  &__subtitle {
    margin-bottom: 8px;
  }

  &__complex {
    &-promo {
      margin-bottom: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        //flex-direction: column;
        //align-items: flex-start;
      }
      p {
        background: color(gradient);
        border-radius: 4px;
        padding: 0 8px;
        margin-right: 4px;
        margin-bottom: 4px;
        @media (max-width: getBreakpoint(tablet)) {
          border-radius: 5px;
          padding: 0 5px;
          margin-bottom: 0;
        }
      }
      span {
        background: color(gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-overpromo {
      background: color(gradient);
      position: relative;
      border-radius: 4px;
      margin-right: auto;
      margin-bottom: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 8px;
      }
      span {
        position: relative;
        padding: 2px 4px;
        background: color(gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (max-width: getBreakpoint(tablet)) {
          padding: 0 3px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background: color(white);
        border-radius: 3px;
      }
    }
    &-month {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  &__card {
    border-radius: 12px;
    border: 1px solid color(gray-darker);
    margin-bottom: 8px;
    padding: 16px;
    overflow: hidden;
    @media (max-width: getBreakpoint(tablet)) {
      align-items: flex-start;
    }

    &-date {
      padding-right: 8px;
      width: 193px;
      flex-shrink: 0;
      @media (max-width: getBreakpoint(tablet)) {
        min-width: 35.5%;
        max-width: 35.5%;
        margin-right: 24px;
      }
    }

    &-logo {
      max-width: calc(100% - 400px);
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 10px;
        max-width: 100%;
      }
      img {
        width: 136px;
        height: 34px;
        object-position: left;
        object-fit: contain;
        margin-bottom: 8px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 112px;
          height: 28px;
          margin-bottom: 4px;
          display: flex;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 104px;
          height: 26px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 92px;
          height: 23px;
        }
      }
      p {
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
    &-tariff {
      flex-grow: 1;
      max-width: calc(100% - 193px);
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: column;
        align-items: flex-start;
        max-width: calc(64.5% - 24px);
      }
    }

    &-services {
      gap: 8px;
      width: 188px;
      margin-right: 16px;
      margin-left: auto;
      flex-shrink: 0;
      .d-icon, img {
        margin-right: 4px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: unset;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 12px;
      }
    }
    &-price {
      width: 190px;
      flex-shrink: 0;
    }
  }

  &__footer {
    border-radius: 12px;
    padding: 24px;
    border: 1px solid color(gray-darker);
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      align-items: flex-start;
    }
    .d-btn {
      margin-left: auto;
      padding: 16px 64px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: 0;
        width: 100%;
        max-width: 400px;
        padding: 8px 40px;
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 42px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }
    p {
      max-width: 400px;
      margin-right: 24px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
